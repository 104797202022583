import Vue from 'vue'
import Vuex from 'vuex'

import invitation from './invitation'

Vue.use(Vuex)

const SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN'

export default new Vuex.Store({
  modules: {
    invitation
  },

  state: {
    isMenuOpened: false
  },

  getters: {
    isMenuOpened: (state) => state.isMenuOpened
  },

  mutations: {
    [SET_IS_MENU_OPEN] (state, data) {
      state.isMenuOpened = data
    }
  },

  actions: {
    toggleMenu ({ dispatch, state }) {
      if (state.isMenuOpened) {
        dispatch('closeMenu')
      } else {
        dispatch('openMenu')
      }
    },

    openMenu ({ commit }) {
      commit(SET_IS_MENU_OPEN, true)
      document.body.style.overflow = 'hidden'
    },

    closeMenu ({ commit }) {
      commit(SET_IS_MENU_OPEN, false)
      document.body.style.overflow = 'auto'
    }
  }
})
