<template functional>
  <svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.21592 1.5329L4.45455 1.84609L4.69317 1.5329C4.84971 1.32745 5.11798 1.01226 5.45785 0.750908C5.80027 0.487604 6.18772 0.3 6.58902 0.3C7.66922 0.3 8.60909 1.30634 8.60909 2.62169C8.60909 3.21423 8.41231 3.7748 8.06028 4.20589L8.04138 4.22904L8.0351 4.24091C8.01167 4.26672 7.99016 4.28766 7.96404 4.3123L7.96402 4.31228L7.961 4.31522L7.92439 4.35075L7.92437 4.35072L7.92123 4.35386L4.45449 7.81936L1.11285 4.48462L1.09767 4.45838L1.06384 4.42732C0.581647 3.98468 0.3 3.33168 0.3 2.62169C0.3 1.30634 1.23988 0.3 2.32007 0.3C2.72138 0.3 3.10882 0.487604 3.45124 0.750908C3.79111 1.01226 4.05938 1.32745 4.21592 1.5329Z" stroke="currentColor" stroke-width="0.6"/>
  </svg>
</template>

<script>
export default {
  name: 'IconHeart'
}
</script>

<style>
</style>
