<template lang="pug">
  .made-by-invito
    span made with
    icon-heart
    span by
    a.link(
      target="_blank"
      href="https://instagram.com/invito.link") invito.link
</template>

<script>
import IconHeart from '@/components/svg/IconHeart'

export default {
  name: 'MadeByInvito',

  components: {
    IconHeart
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .made-by-invito {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    z-index: 2;

    &__icon {
      height: .9rem !important;
      width: .9rem;
      color: inherit;
    }

    svg {
      height: 1rem;
      width: 1rem;
    }

    .link {
      color: inherit;
    }

    *:not(:last-child) {
      margin-right: .6rem;
    }
  }
</style>
