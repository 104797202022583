import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWow from 'vue-wow'

import VAnimate from '@/components/global/VAnimate'
import VSpinner from '@/components/global/VSpinner'

Vue.use(VueWow)
Vue.component('VAnimate', VAnimate)
Vue.component('VSpinner', VSpinner)

Vue.config.productionTip = false

const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
