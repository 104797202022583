<template functional>
  <svg width="69" height="21" viewBox="0 0 69 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.6 19.32V19.6H0V19.32H1.4V6.44H0V6.16H4.2V19.32H5.6ZM2.8 3.36C2.31467 3.36 1.91333 3.20133 1.596 2.884C1.27867 2.56667 1.12 2.16533 1.12 1.68C1.12 1.19467 1.27867 0.793334 1.596 0.476001C1.91333 0.158667 2.31467 0 2.8 0C3.28533 0 3.68667 0.158667 4.004 0.476001C4.32133 0.793334 4.48 1.19467 4.48 1.68C4.48 2.16533 4.32133 2.56667 4.004 2.884C3.68667 3.20133 3.28533 3.36 2.8 3.36Z" fill="currentColor"/>
    <path d="M22.4011 19.32V19.6H16.8011V19.32H18.2011V6.58C18.0331 6.44933 17.8371 6.33733 17.6131 6.244C17.4078 6.15067 17.1651 6.06667 16.8851 5.992C16.6238 5.91733 16.3158 5.88 15.9611 5.88C15.4011 5.88 14.8971 5.98267 14.4491 6.188C14.0011 6.37467 13.6184 6.58 13.3011 6.804C12.9278 7.06533 12.6011 7.364 12.3211 7.7V19.32H13.7211V19.6H8.12109V19.32H9.52109V6.44H8.12109V6.16H12.3211V7.28C12.6011 6.96267 12.9278 6.68267 13.3011 6.44C13.6184 6.23467 14.0011 6.048 14.4491 5.88C14.8971 5.69333 15.4011 5.6 15.9611 5.6C16.6518 5.6 17.3051 5.74 17.9211 6.02C18.5371 6.28133 19.0691 6.664 19.5171 7.168C19.9838 7.672 20.3478 8.288 20.6091 9.016C20.8704 9.744 21.0011 10.5653 21.0011 11.48V19.32H22.4011Z" fill="currentColor"/>
    <path d="M37.2367 6.44H35.2767L30.2367 19.6H29.6767L24.6367 6.44H23.2367V6.16H29.1167V6.44H27.7167L31.3567 15.96L34.9967 6.44H32.7567V6.16H37.2367V6.44Z" fill="currentColor"/>
    <path d="M43.6898 19.32V19.6H38.0898V19.32H39.4898V6.44H38.0898V6.16H42.2898V19.32H43.6898ZM40.8898 3.36C40.4045 3.36 40.0032 3.20133 39.6858 2.884C39.3685 2.56667 39.2098 2.16533 39.2098 1.68C39.2098 1.19467 39.3685 0.793334 39.6858 0.476001C40.0032 0.158667 40.4045 0 40.8898 0C41.3752 0 41.7765 0.158667 42.0938 0.476001C42.4112 0.793334 42.5698 1.19467 42.5698 1.68C42.5698 2.16533 42.4112 2.56667 42.0938 2.884C41.7765 3.20133 41.3752 3.36 40.8898 3.36Z" fill="currentColor"/>
    <path d="M53.4909 17.64C53.3416 18.3493 53.0149 18.9467 52.5109 19.432C52.0069 19.9173 51.4003 20.16 50.6909 20.16C50.2616 20.16 49.8416 20.0573 49.4309 19.852C49.0389 19.6467 48.6843 19.3573 48.3669 18.984C48.0683 18.592 47.8163 18.1253 47.6109 17.584C47.4243 17.024 47.3309 16.3893 47.3309 15.68V6.44H45.9309V6.16H47.3309V2.8L50.1309 0.28V6.16H52.3709V6.44H50.1309V19.74C50.1683 19.7773 50.2243 19.8053 50.2989 19.824C50.4483 19.8613 50.5789 19.88 50.6909 19.88C51.3256 19.88 51.8669 19.6653 52.3149 19.236C52.7629 18.8067 53.0616 18.2747 53.2109 17.64H53.4909Z" fill="currentColor"/>
    <path d="M62.0331 19.88C62.5185 19.88 62.9665 19.824 63.3771 19.712C63.7878 19.6 64.1425 19.4693 64.4411 19.32C64.7771 19.152 65.0945 18.9653 65.3931 18.76V7C65.0945 6.79467 64.7771 6.608 64.4411 6.44C64.1425 6.29067 63.7878 6.16 63.3771 6.048C62.9665 5.936 62.5185 5.88 62.0331 5.88C61.5478 5.88 61.0998 5.936 60.6891 6.048C60.2785 6.16 59.9238 6.29067 59.6251 6.44C59.2891 6.608 58.9718 6.79467 58.6731 7V18.76C58.9718 18.9653 59.2891 19.152 59.6251 19.32C59.9238 19.4693 60.2785 19.6 60.6891 19.712C61.0998 19.824 61.5478 19.88 62.0331 19.88ZM62.0331 5.6C62.9105 5.6 63.7411 5.78667 64.5251 6.16C65.3278 6.51467 66.0278 7.01867 66.6251 7.672C67.2411 8.30667 67.7265 9.072 68.0811 9.968C68.4358 10.864 68.6131 11.8347 68.6131 12.88C68.6131 13.9253 68.4358 14.896 68.0811 15.792C67.7265 16.688 67.2411 17.4627 66.6251 18.116C66.0278 18.7507 65.3278 19.2453 64.5251 19.6C63.7411 19.9733 62.9105 20.16 62.0331 20.16C61.1558 20.16 60.3158 19.9733 59.5131 19.6C58.7291 19.2453 58.0291 18.7507 57.4131 18.116C56.8158 17.4627 56.3398 16.688 55.9851 15.792C55.6305 14.896 55.4531 13.9253 55.4531 12.88C55.4531 11.8347 55.6305 10.864 55.9851 9.968C56.3398 9.072 56.8158 8.30667 57.4131 7.672C58.0291 7.01867 58.7291 6.51467 59.5131 6.16C60.3158 5.78667 61.1558 5.6 62.0331 5.6Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLogo'
}
</script>

<style>
</style>
