<template functional>
  <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="30" y1="0.5" x2="-4.23013e-08" y2="0.499997" stroke="currentColor"/>
    <line x1="30" y1="13.5" x2="-4.61131e-08" y2="13.5" stroke="currentColor"/>
    <line x1="30" y1="7" x2="10" y2="7" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMenu'
}
</script>

<style>
</style>
